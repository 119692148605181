<template>
  <div class="search_flex">
    <div class="search_left">
      <div v-if="isDetail">商家：{{ $route.query.storeName }}</div>
      <div v-if="compType === 'customer-unpack-detail'">
        客户：{{ $route.query.customerName }}
      </div>
      <!-- 战区 -->
      <salesArea
        v-if="
          compType === 'city-unpack' ||
            compType === 'store-unpack' ||
            compType === 'customer-unpack' ||
            compType === 'delivery-unpack' ||
            compType === 'fault-cycle' ||
            isCityUnpackAnaly
        "
        :isCityUnpackAnaly="isCityUnpackAnaly"
        :hval="salesArea"
        @change="salesAreaChange"
        @saleArea="saleArea"
      />
      <inputComp
        v-if="
          compType === 'store-unpack-detail' ||
            compType === 'delivery-unpack' ||
            compType === 'customer-unpack-detail'
        "
        :hval="commodityName"
        @blur="commodityNameBlur"
        placeholder="商品名称"
      />
      <inputComp
        v-if="
          compType === 'delivery-unpack' ||
            compType === 'customer-unpack' ||
            compType === 'fault-cycle'
        "
        :hval="customerName"
        @blur="customerNameBlur"
        placeholder="客户名称"
      />
      <selectComp
        v-if="compType === 'city-unpack' || compType === 'store-unpack'"
        :hval="type"
        :data="typeList"
        @change="typeChange"
        :clearable="false"
        placeholder="统计方式"
      />
      <!-- 报障周期分析 -->
      <selectComp
        v-if="compType === 'fault-cycle'"
        :hval="cycleType"
        :data="cycleTypeList"
        @change="cycleTypeChange"
        :clearable="false"
      />
      <selectComp
        v-else
        :hval="timeType"
        :data="timeTypeList"
        @change="timeTypeChange"
        :clearable="false"
        placeholder="时间类型"
      />
      <dateComp
        v-show="timeType === 1 || timeType === 2"
        :hval="date"
        @change="dateChange"
      />
      <div class="month_box" v-show="timeType === 3">
        <el-date-picker
          v-model="beginMonth"
          type="month"
          size="small"
          placeholder="起始月"
          :clearable="false"
          style="width:126px"
          @change="beginMonthChange"
        >
        </el-date-picker>
        <div style="margin:0 5px">-</div>
        <el-date-picker
          v-model="endMonth"
          type="month"
          size="small"
          placeholder="截止月"
          :clearable="false"
          style="width:126px"
          @change="endMonthChange"
        >
        </el-date-picker>
      </div>
      <!-- compType === 'store-unpack-analy' && !isCityUnpackAnaly  城市开箱不良的问题分析页面 -->
      <selectComp
        v-if="
          compType === 'sku' ||
            compType === 'fault' ||
            (compType === 'store-unpack-analy' && !isCityUnpackAnaly)
        "
        :hval="dimensional"
        :data="dimensionalList"
        @change="dimensionalChange"
        placeholder="分析维度"
        :clearable="false"
      />
      <selectComp
        v-if="orderTypeList.length"
        :data="orderTypeList"
        :hval="orderType"
        @change="orderTypeChange"
        :clearable="false"
        style="width:220px"
      />
      <!-- 商家数量 -->
      <selectComp
        v-if="compType === 'customer-unpack'"
        :data="storeCountConditionList"
        :hval="storeCountCondition"
        @change="storeCountConditionChange"
        placeholder="商家数量"
      />
      <!-- 商家搜索 -->
      <merchant
        v-if="!isDetail && compType !== 'customer-unpack' && !isStore"
        :hval="storeName"
        @storeNameChange="storeNameChange"
      />
      <btnComp
        v-if="
          (compType === 'city-unpack' ||
            compType === 'store-unpack' ||
            compType === 'delivery-unpack' ||
            compType === 'customer-unpack-detail' ||
            compType === 'sku' ||
            compType === 'fault' ||
            compType === 'fault-cycle' ||
            isCityUnpackAnaly) &&
            !isStore
        "
        btnType="text"
        style="font-size:14px;margin-right:10px"
        @click="rankClick"
        >商家排名</btnComp
      >
      <btnGroup @search="search" @reset="reset" />
      <btnComp v-if="isExportBtn" @click="exportData" v-permission="'导出数据'">导出数据</btnComp>
    </div>
  </div>
</template>
<script>
import merchant from '@/views/components/merchant'
import salesArea from '@/views/components/salesArea'
import { formatDate, diffDate, localGet } from '@/utils/common'
export default {
  components: {
    merchant,
    salesArea
  },
  props: {
    // 区分哪里用到这个组件
    compType: {
      type: String,
      require: true
    },
    // 点击发货排名选的商家信息
    storeInfo: {
      type: Object,
      default: () => {}
    },
    // 标识是否是城市开箱不良的问题分析页面
    isCityUnpackAnaly: {
      type: Boolean,
      default: false
    },
    isExportBtn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShow: false,
      isDisabled: false,
      curDate: null, // 累计发货量默认是传空， 月度 默认是当前月
      commodityName: '',
      customerName: '',
      storeId: '',
      storeName: '',
      beginMonth: '',
      endMonth: '',
      date: [],
      startTime: '',
      endTime: '',
      salesArea: [],
      level: '',
      cityId: '',
      storeCountCondition: '',
      cycleType: 1, // 针对报障周期分析页面特殊字段
      type: 1,
      orderType: 1,
      timeType: 3,
      dimensional: 2,
      typeList: [
        { label: '按发货', value: 1 },
        { label: '按客户', value: 2 },
        { label: '按订单', value: 3 }
      ],
      dimensionalList: [
        { label: '报修', value: 1 },
        { label: '开箱不良', value: 2 }
      ],
      cycleTypeList: [
        { label: '按报修月份', value: 1 },
        { label: '按发货月份', value: 2 }
      ],
      storeCountConditionList: [
        { label: '商家数量>1', value: 1 },
        { label: '商家数量=1', value: 2 }
      ]
    }
  },
  watch: {
    storeInfo: {
      handler (val) {
        this.storeName = val.storeName
        this.storeId = val.storeId
      },
      deep: true
    }
  },
  computed: {
    // 是否是商家角色
    isStore () {
      return localGet('loginUserInfo').businessRole === 'store'
    },
    timeTypeList () {
      let arr = [
        { label: '按发货日期', value: 1 },
        { label: '按发货月份', value: 3 }
      ]
      switch (this.compType) {
        // fault, store-unpack-detail,
        // store-unpack-analy, delivery-unpack, customer-unpack
        // customer-unpack-detail
        // 以上取默认
        case 'city-unpack':
          arr = [
            { label: '按天', value: 1 },
            { label: '按周', value: 2 },
            { label: '按月', value: 3 }
          ]
          break
        case 'store-unpack':
          arr = [
            { label: '按天', value: 1 },
            { label: '按月', value: 3 }
          ]
          break
        case 'sku':
          arr = [
            { label: '按报修日期', value: 1 },
            { label: '按报修月份', value: 3 }
          ]
          break
      }
      return arr
    },
    orderTypeList () {
      let arr = []
      switch (this.compType) {
        case 'store-unpack':
          arr = [
            { label: '开箱不良率倒序', value: 1 },
            { label: '发货量倒序', value: 2 },
            { label: '开箱不良数量倒序', value: 3 }
          ]
          break
        case 'store-unpack-detail':
          arr = [
            { label: '不良率倒序', value: 1 },
            { label: '发货日期倒序', value: 2 },
            { label: '发货日期顺序', value: 3 },
            { label: '发货数量倒序', value: 4 },
            { label: '不良数量倒序', value: 5 }
          ]
          break
        case 'fault':
          arr = [
            { label: '报修或开箱不良数量倒序', value: 1 },
            { label: '报修或开箱不良比率倒序', value: 2 }
          ]
          break
        case 'delivery-unpack':
          arr = [
            { label: '不良率倒序', value: 1 },
            { label: '发货日期倒序', value: 2 },
            { label: '发货日期顺序', value: 3 },
            { label: '发货数量倒序', value: 4 },
            { label: '不良数量倒序', value: 5 }
          ]
          break
        case 'customer-unpack':
          arr = [
            { label: '开箱不良率倒序', value: 1 },
            { label: '发货量倒序', value: 2 },
            { label: '开箱不良数量倒序', value: 3 }
          ]
          break
        case 'customer-unpack-detail':
          arr = [
            { label: '不良率倒序', value: 1 },
            { label: '发货日期倒序', value: 2 },
            { label: '发货日期顺序', value: 3 },
            { label: '发货数量倒序', value: 4 },
            { label: '不良数量倒序', value: 5 }
          ]
          break
      }
      return arr
    },
    // 是否是明细页面（明细页面不需要商家名搜索）(商家开箱不良的问题分析、sku统计、发货明细)
    isDetail () {
      const name = this.$route.name
      return (
        name === 'store-unpack-sku' ||
        name === 'store-unpack-analy' ||
        name === 'store-unpack-detail'
      )
    }
  },
  mounted () {
    //  跳转详情页面需要获取query的时间
    this.handleQuery()
  },
  methods: {
    exportData () {
      this.$emit('exportData')
    },
    // 子组件传过来战区信息
    saleArea (salesArea, level, cityId) {
      this.initSalesArea = salesArea
      this.initLevel = level
      this.initCityId = cityId
      this.level = level
      this.cityId = cityId
      this.salesArea = salesArea
      this.$emit('saleArea', salesArea, level, cityId)
    },
    init () {
      // 按日统计， 以今天为起点，向前推6天，共计7天。
      // 按月统计，以当前月伟起点，向前推5月，共计6月。
      // 按周统计，以当前周伟起点，向前推5周，共计6周。
      if (this.timeType === 1) {
        this.endTime = formatDate(new Date())
        this.startTime = diffDate(-6, 'days', true)
        this.date = [diffDate(-6, 'days'), new Date()]
      } else if (this.timeType === 3) {
        this.endMonth = new Date()
        this.beginMonth = diffDate(-5, 'months')
        this.endTime = formatDate(new Date()).slice(0, 7)
        this.startTime = diffDate(-5, 'months', true).slice(0, 7)
      } else {
        this.endTime = formatDate(new Date())
        this.startTime = diffDate(-7, 'weeks', true)
        this.date = [diffDate(-7, 'weeks'), new Date()]
      }
    },
    //  处理跳转详情页面需要自动获取列表选择的时间
    handleQuery () {
      if (JSON.stringify(this.$route.query) === '{}') {
        if (
          this.compType === 'delivery-unpack' ||
          this.compType === 'customer-unpack' ||
          this.compType === 'customer-unpack-detail'
        ) {
          this.timeType = 1
        } else {
          this.timeType = 3
        }
        this.init()
      } else {
        // 跳转的详情页面
        const {
          timeType,
          startTime,
          endTime,
          time,
          storeId,
          storeName,
          salesArea,
          level,
          cityId
        } = this.$route.query
        this.timeType = +timeType
        this.storeId = storeId
        this.storeName = storeName
        // 表示是城市开箱不良的问题分析页面
        if (this.isCityUnpackAnaly) {
          this.startTime = time
          this.endTime = time
          this.level = level
          this.cityId = cityId
          this.salesArea = salesArea.split(',')
        } else {
          this.startTime = startTime
          this.endTime = endTime
        }
        if (+timeType === 3) {
          this.beginMonth = new Date(this.startTime)
          this.endMonth = new Date(this.endTime)
        } else {
          this.date = [new Date(this.startTime), new Date(this.endTime)]
        }
      }
    },
    salesAreaChange (salesArea, level, cityId) {
      this.salesArea = salesArea
      this.level = level
      this.cityId = cityId
    },
    commodityNameBlur (val) {
      this.commodityName = val
    },
    customerNameBlur (val) {
      this.customerName = val
    },
    storeNameChange (val) {
      this.storeId = val.partnerId
      this.storeName = val.partnerName
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    typeChange (val) {
      this.type = val
    },
    timeTypeChange (val) {
      this.timeType = val
      this.init()
    },
    orderTypeChange (val) {
      this.orderType = val
    },
    storeCountConditionChange (val) {
      this.storeCountCondition = val
    },
    beginMonthChange (val) {
      this.startTime = formatDate(val).slice(0, 7)
    },
    endMonthChange (val) {
      this.endTime = formatDate(val).slice(0, 7)
    },
    dimensionalChange (val) {
      this.dimensional = val
    },
    cycleTypeChange (val) {
      this.cycleType = val
    },
    rankClick () {
      this.$emit('rankClick')
    },
    search () {
      if (this.timeType !== 3 && !this.startTime && !this.endTime) {
        this.$message.error('请选择起始时间')
        return
      }
      const params = {
        commodityName: this.commodityName,
        customerName: this.customerName,
        storeCountCondition: this.storeCountCondition,
        level: this.level,
        cityId: this.cityId,
        salesArea: this.salesArea,
        type: this.type,
        timeType: this.timeType,
        orderType: this.orderType,
        dimensional: this.dimensional,
        storeId: this.storeId,
        storeName: this.storeName,
        startTime: this.startTime,
        endTime: this.endTime,
        cycleType: this.cycleType
      }
      this.$emit('search', params)
    },
    reset () {
      // 重置后赋值初始值
      this.level = this.initLevel
      this.cityId = this.initCityId
      this.salesArea = this.initSalesArea

      this.type = 1
      this.timeType = 3
      this.orderType = 1
      this.dimensional = 2
      this.cycleType = 1
      this.storeCountCondition = ''
      this.commodityName = ''
      this.customerName = ''
      this.storeId = ''
      this.storeName = ''
      this.handleQuery()
      const params = {
        commodityName: this.commodityName,
        customerName: this.customerName,
        storeCountCondition: this.storeCountCondition,
        level: this.level,
        cityId: this.cityId,
        salesArea: this.salesArea,
        type: this.type,
        timeType: this.timeType,
        dimensional: this.dimensional,
        orderType: this.orderType,
        storeId: this.storeId,
        storeName: this.storeName,
        startTime: this.startTime,
        endTime: this.endTime,
        cycleType: this.cycleType
      }
      this.$emit('reset', params)
    }
  }
}
</script>
<style lang="scss" scoped>
.month_box {
  display: flex;
  align-items: center;
}
</style>
