var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search_flex"},[_c('div',{staticClass:"search_left"},[(_vm.isDetail)?_c('div',[_vm._v("商家："+_vm._s(_vm.$route.query.storeName))]):_vm._e(),(_vm.compType === 'customer-unpack-detail')?_c('div',[_vm._v(" 客户："+_vm._s(_vm.$route.query.customerName)+" ")]):_vm._e(),(
        _vm.compType === 'city-unpack' ||
          _vm.compType === 'store-unpack' ||
          _vm.compType === 'customer-unpack' ||
          _vm.compType === 'delivery-unpack' ||
          _vm.compType === 'fault-cycle' ||
          _vm.isCityUnpackAnaly
      )?_c('salesArea',{attrs:{"isCityUnpackAnaly":_vm.isCityUnpackAnaly,"hval":_vm.salesArea},on:{"change":_vm.salesAreaChange,"saleArea":_vm.saleArea}}):_vm._e(),(
        _vm.compType === 'store-unpack-detail' ||
          _vm.compType === 'delivery-unpack' ||
          _vm.compType === 'customer-unpack-detail'
      )?_c('inputComp',{attrs:{"hval":_vm.commodityName,"placeholder":"商品名称"},on:{"blur":_vm.commodityNameBlur}}):_vm._e(),(
        _vm.compType === 'delivery-unpack' ||
          _vm.compType === 'customer-unpack' ||
          _vm.compType === 'fault-cycle'
      )?_c('inputComp',{attrs:{"hval":_vm.customerName,"placeholder":"客户名称"},on:{"blur":_vm.customerNameBlur}}):_vm._e(),(_vm.compType === 'city-unpack' || _vm.compType === 'store-unpack')?_c('selectComp',{attrs:{"hval":_vm.type,"data":_vm.typeList,"clearable":false,"placeholder":"统计方式"},on:{"change":_vm.typeChange}}):_vm._e(),(_vm.compType === 'fault-cycle')?_c('selectComp',{attrs:{"hval":_vm.cycleType,"data":_vm.cycleTypeList,"clearable":false},on:{"change":_vm.cycleTypeChange}}):_c('selectComp',{attrs:{"hval":_vm.timeType,"data":_vm.timeTypeList,"clearable":false,"placeholder":"时间类型"},on:{"change":_vm.timeTypeChange}}),_c('dateComp',{directives:[{name:"show",rawName:"v-show",value:(_vm.timeType === 1 || _vm.timeType === 2),expression:"timeType === 1 || timeType === 2"}],attrs:{"hval":_vm.date},on:{"change":_vm.dateChange}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.timeType === 3),expression:"timeType === 3"}],staticClass:"month_box"},[_c('el-date-picker',{staticStyle:{"width":"126px"},attrs:{"type":"month","size":"small","placeholder":"起始月","clearable":false},on:{"change":_vm.beginMonthChange},model:{value:(_vm.beginMonth),callback:function ($$v) {_vm.beginMonth=$$v},expression:"beginMonth"}}),_c('div',{staticStyle:{"margin":"0 5px"}},[_vm._v("-")]),_c('el-date-picker',{staticStyle:{"width":"126px"},attrs:{"type":"month","size":"small","placeholder":"截止月","clearable":false},on:{"change":_vm.endMonthChange},model:{value:(_vm.endMonth),callback:function ($$v) {_vm.endMonth=$$v},expression:"endMonth"}})],1),(
        _vm.compType === 'sku' ||
          _vm.compType === 'fault' ||
          (_vm.compType === 'store-unpack-analy' && !_vm.isCityUnpackAnaly)
      )?_c('selectComp',{attrs:{"hval":_vm.dimensional,"data":_vm.dimensionalList,"placeholder":"分析维度","clearable":false},on:{"change":_vm.dimensionalChange}}):_vm._e(),(_vm.orderTypeList.length)?_c('selectComp',{staticStyle:{"width":"220px"},attrs:{"data":_vm.orderTypeList,"hval":_vm.orderType,"clearable":false},on:{"change":_vm.orderTypeChange}}):_vm._e(),(_vm.compType === 'customer-unpack')?_c('selectComp',{attrs:{"data":_vm.storeCountConditionList,"hval":_vm.storeCountCondition,"placeholder":"商家数量"},on:{"change":_vm.storeCountConditionChange}}):_vm._e(),(!_vm.isDetail && _vm.compType !== 'customer-unpack' && !_vm.isStore)?_c('merchant',{attrs:{"hval":_vm.storeName},on:{"storeNameChange":_vm.storeNameChange}}):_vm._e(),(
        (_vm.compType === 'city-unpack' ||
          _vm.compType === 'store-unpack' ||
          _vm.compType === 'delivery-unpack' ||
          _vm.compType === 'customer-unpack-detail' ||
          _vm.compType === 'sku' ||
          _vm.compType === 'fault' ||
          _vm.compType === 'fault-cycle' ||
          _vm.isCityUnpackAnaly) &&
          !_vm.isStore
      )?_c('btnComp',{staticStyle:{"font-size":"14px","margin-right":"10px"},attrs:{"btnType":"text"},on:{"click":_vm.rankClick}},[_vm._v("商家排名")]):_vm._e(),_c('btnGroup',{on:{"search":_vm.search,"reset":_vm.reset}}),(_vm.isExportBtn)?_c('btnComp',{directives:[{name:"permission",rawName:"v-permission",value:('导出数据'),expression:"'导出数据'"}],on:{"click":_vm.exportData}},[_vm._v("导出数据")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }